import { createContext } from "react";

let user = {
    userId: '',
    username: '',
    password: '',
    fullName: '',
    uniqueNumber: '',
    isActive: 0,
    deactivatedBy: '',
    createdBy: '',
    dateCreated: '',
    roles: ''
};
let authStatus = false;
// let userDataString = window.localStorage.getItem("CURRENT_USER");
let userDataString = window.localStorage.getItem("USER_PROFILE");

if (userDataString) {
  user = JSON.parse(userDataString);
  authStatus = true;
}

const UserContext = createContext({
  authenticated: authStatus,
  userData: user,
});
export default UserContext;
