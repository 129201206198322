import type { ApiResponse } from "../models/ApiResponse";
import type { CurrentUserData } from "../models/CurrentUser";
import type { ProcessResponse } from "../models/ProcessResponse";
import { getAxios } from "../helpers/api";
import { config } from '../environments/environment'
// const { publicRuntimeConfig } = getConfig();
 
class _AuthServices {
    private localStorageKeys = {
        currentUser: "CURRENT_USER",
        userProfile: "USER_PROFILE"
    }
    async Login(username: string, password: string): Promise<ProcessResponse> {
         try {
            const res = await getAxios().post("/User/system/user/login", { username, password });
            const response = res.data as ApiResponse<CurrentUserData>;
            // logger.logInfo(response);
            if (response.isSuccessful) {
                this.SetCurrentUser(response.data);
                return { Successful: true, message: "Login Successful. Please wait" ,data:response.data} as ProcessResponse;
            }
            return { Successful: false, message: "Login failed. Please check credentials and try again" } as ProcessResponse;

        } catch (error) {
            // logger.logError(error);
            return { Successful: false, message: "Login failed. Please check credentials and try again" } as ProcessResponse;
        }
    }

    async Logout(userId: number): Promise<ProcessResponse> {
         try {
            const res = await getAxios().get("/User/logout/user/"+userId);
            const response = res.data as ApiResponse<CurrentUserData>;
            // logger.logInfo(response);
            if (response.isSuccessful) {
                this.ClearSession();
                return { Successful: true, message: "Logout Successful. Please wait" } as ProcessResponse;
            }
            return { Successful: false, message: "Logout failed." } as ProcessResponse;

        } catch (error) {
            // logger.logError(error);
            return { Successful: false, message: "Logout failed." } as ProcessResponse;
        }
    }
    async RequestPasswordReset(usernameOrEmail: string,passresetLinkBaseUri:string=config.url.PWD_RESET_URL): Promise<ProcessResponse> {
         try {
            const res = await getAxios().post("/User/request/password/reset/", { usernameOrEmail, passresetLinkBaseUri });
            const response = res.data as ApiResponse<any>;
            // logger.logInfo(response);
            if (response.isSuccessful) {
                
                return { Successful: true, message: response.message } as ProcessResponse;
            }
            return { Successful: false, message:  response.message } as ProcessResponse;

        } catch (error) {
            // logger.logError(error);
            return { Successful: false, message: "Logout failed." } as ProcessResponse;
        }
    }
     async ResetPassword(username: string,newPassword:string): Promise<ProcessResponse> {
         try {
            const res = await getAxios().post("/User/reset/password", { username, newPassword });
            const response = res.data as ApiResponse<any>;
            // logger.logInfo(response);
            if (response.isSuccessful) {
                
                return { Successful: true, message: response.message } as ProcessResponse;
            }
            return { Successful: false, message:  response.message } as ProcessResponse;

        } catch (error) {
            // logger.logError(error);
            return { Successful: false, message: "Reset failed." } as ProcessResponse;
        }
    }

    GetCurrentUser(): CurrentUserData|any {
        var userDataString = window.localStorage.getItem(this.localStorageKeys.currentUser);

        if (!userDataString){
           return { Successful: false, message: "Invalid Session",data:{} } as ProcessResponse;
        }
        
         return { Successful: true, message: "Valid Session. Please wait",data:JSON.parse(userDataString) } as ProcessResponse;
       
    }

    IsSessionValid(): boolean {
        var userData = this.GetCurrentUser();
        if (userData && !userData.Successful ) return false;
        return true;
        // return userData.adminUserId != null && userData.uniqueNumber != null;
    }

    SetCurrentUser(userData?: CurrentUserData) {
        window.localStorage.setItem(this.localStorageKeys.currentUser, JSON.stringify(userData));
    }

    ClearSession() {
        window.localStorage.removeItem(this.localStorageKeys.currentUser);
        window.localStorage.removeItem(this.localStorageKeys.userProfile);
    }


}

export const AuthService = new _AuthServices();