// import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";


class _UserService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER",
        userProfile: "USER_PROFILE",
    }
    async RegisterUser(username: string, firstName: string,lastName: string,phoneNumber: string,email: string ,createdBy: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/onboard/internal/user", { username,firstName, lastName,phoneNumber,email,createdBy});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Register. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async AddUserAddress(userId: number, location: string,city:string,postalCode:string,countryId=1, address1?: string,address2?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/add/user/address", { userId, location,city,postalCode,countryId, address1, address2 });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Address. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async UpdateUserAddress(userId: number, location: string, address1?: string,address2?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/update/user/address", { userId, location, address1, address2 });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Address. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async AddCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/add/company/client/info", { userId,clientId, companyName, email, phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async UpdateCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/update/company/info", { userId,clientId, companyName, email, phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetUserById(id: number): Promise<UserInfo|any> {
        try {
            const res = await getAxios().get("/User/get/user/by/id/" + id);
            var response = res.data as ApiResponse<UserInfo>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 this.SetCurrentUserProfile(response.data);
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }

     async GetAllUsers(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/all/users");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

      async GetAdminById(id: number): Promise<UserInfo|any> {
        try {
            const res = await getAxios().get("/User/get/user/by/id/" + id);
            var response = res.data as ApiResponse<UserInfo>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                //  this.SetCurrentUser(response.data);
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }
 
    async UpdateUserInfo(data: UserInfo) {
        try {
            const res = await getAxios().post("/User/update/user", data);
            const data_res = res.data as ApiResponse<any>;
             if (data_res.isSuccessful) return { Successful: true, message: data_res.message };

            return { Successful: false, message: data_res.message ?? "Unable to Update. Please try again" }
        } catch (error) {
            
            return null;
        }
    }



    async SaveUserImage(image:any, userId:string) {
        let data = new FormData();
        data.append('imageCollection', image);
        data.append('userId', userId);
        try {
            const res = await getAxios().post("/User/upload/user/image", data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

    async SaveCompanyDocuments(image:any, userId:string) {
        let data = new FormData();
        data.append('docCollection', image);
        data.append('userId', userId);
        
        try {
            const res = await getAxios().post("/User/upload/company/client/documents", data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

    SetCurrentUser(userData?: any) {
        window.localStorage.setItem(this.localStorageKeys.currentUser, JSON.stringify(userData));
    }

    SetCurrentUserProfile(userData?: any) {
        let actualUser = window.localStorage.getItem("CURRENT_USER");
        if (actualUser) {
           let user = JSON.parse(actualUser);
           if(user && user.userId == userData.userId) {
               window.localStorage.setItem(this.localStorageKeys.userProfile, JSON.stringify(userData));
           }
        }
    }

    async changePassword(userId: number, oldPassword: string, newPassword: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/reset/password/on/dashboard", { userId, oldPassword, newPassword});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to change password. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async ActivateUser(userId: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/activate/user", { userId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable Activate. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async DeactivateUser(userId: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/deactivate/user", { userId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable Deactivate. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetUserStats(id: number): Promise<UserInfo|any> {
        try {
            const res = await getAxios().get("/User/get/user/counts");
            var response = res.data as ApiResponse<UserInfo>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                //  this.SetCurrentUser(response.data);
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }
    async SaveUserSignature(image:any, userId:string) {
        let data = new FormData();
        data.append('imageCollection', image);
        data.append('userId', userId);
        try {
            const res = await getAxios().post("/User/upload/user/signature", data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

     async GetCountries(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/countries");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    
    async GetAllInternalUsers(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/internal/users");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.userTypeId) response.data.userTypeId = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }
    

    async GetClientUsers(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/client/users");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }


    async GetRoles(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/roles");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GrantUserRole(userId: number,roleIds:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/grant/user/roles", { userId ,roleIds});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable Grant Roles. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async RevokeUserRole(userId: number,roleId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/remove/user/role", { userId ,roleId});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable Remove Role. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }

    

}

export const UserService = new _UserService();
